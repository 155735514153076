import './404.scss';
import React from 'react';
import { Link } from "react-router-dom";
function Lost() {
    return (
      <div className="Lost">
        <h1>404</h1>
        <h2>Page Not Found</h2>
        <button><Link to='/'>Lets go back</Link></button>
      </div>
    );
  }
  
  export default Lost;