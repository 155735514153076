import React from 'react';
import './pilots.scss';
import { Link } from "react-router-dom";
import ig  from '../../assets/square-instagram.svg';
import youtube  from '../../assets/youtube.svg';
function Pilots() {
    return (
        <div className='pilot'>
            <div className="title">
                <h1>Pilot</h1>
            </div>
            <div className='iframe'>
                <iframe src="https://iframe.mediadelivery.net/embed/144224/a6d5503a-059a-43bc-938d-19d3a19a6d84?autoplay=true&loop=true&muted=true&preload=true" loading="lazy" title="Pilot at Gillette Castle" allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture;" allowfullscreen="true"></iframe>
            </div>
            <div className='content'>
                <h2>Meet Erickendy, Your Expert Drone Pilot!</h2>

               <p>Hi, I am Erickendy, or you can call me Erick, and I am thrilled to be your dedicated drone pilot, ready to deliver exceptional services through Horizon Optical Drones. With a strong background in Software Engineering and design skills from my undergraduate days at Uconn, I bring a unique approach to aerial photography and videography.</p> 

                <p>Over the past five years, I have been deeply engrossed in the world of Software Engineering, honing my coding abilities and leading projects with precision. My passion for technology, coupled with an adventurous spirit, led me to the realm of aerial drones. Drawing on my engineering skills, I built the website you are viewing from scratch, crafting an intuitive platform showcasing the wonders of drone services at Horizon Optical Drones.</p>

                <p>As a licensed commercial drone pilot, I have undergone rigorous training and obtained the necessary certifications to operate drones safely and professionally. At Horizon Optical Drones, safety and precision are my top priorities. You can trust that every flight is conducted with the utmost care and compliance with industry regulations.</p>

                <p>Whether it's aerial surveys, real estate photography, event coverage, or any other required service, I am committed to exceeding your expectations. From conceptualizing the flight plan to capturing stunning aerial footage, I ensure that each mission is flawlessly executed, delivering lasting results.</p>

                <p>Feel free to contact me today, and let's explore the endless possibilities that await with Horizon Optical Drones.</p>
            </div>
            <div className='glance'>
                <h1>At a Glance</h1>
                <div className='glance-content'>
                    <ul>
                        <li>BS in Digital Media and Design from Uconn</li>
                        <li>FAA Part 107 Certified</li>
                        <li>5+ Years of Experience in Software Engineering</li>
                        <li>5+ Years of Experience in Photography and Videography</li>
                        <li>Proficient in Adobe Creative Suite</li>
                        <li>Proficient in HTML, CSS, JavaScript, and React</li>
                    </ul>
                </div>
            </div>
            <hr/>
            <div className='outro'>
                <h1>Discover how our expertise in drone photography and videography can elevate your visual storytelling. Contact today to discuss your project and let us take your vision to new heights.</h1>
                <button><Link to='/contact'>Contact</Link></button>
                <p>Follow us on social media</p>
                <div class="social">
                    <a href="https://www.instagram.com/horizon_optical_drones/" target="_blank" rel="noreferrer"><img className='ig' src={ig} alt='instagram' /></a>
                    
                    <a href="https://www.youtube.com/@horizonopticaldrones" target="_blank" rel="noreferrer"><img className='youtube' src={youtube} alt='youtube' /></a>
                </div>
            </div>
         
        </div>
    )
}

export default Pilots;