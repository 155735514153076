//boiler plate
import React from 'react';
import sun2   from '../../assets/sun_2.jpg';
import home1  from '../../assets/home-1.JPG';
import home2  from '../../assets/home-2.JPG';
import ig  from '../../assets/square-instagram.svg';
import youtube  from '../../assets/youtube.svg';
import { Link } from "react-router-dom";

import './home.scss';
function Home() {
    return (
      <div className="home">
        <div className="homeVideo">
            <iframe src="https://iframe.mediadelivery.net/embed/144224/8869f5a1-c442-465e-a530-c19ee7b49c64?autoplay=true&loop=true&muted=true&preload=true" title="Intro video" loading="lazy" allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture;" allowFullScreen="true"></iframe>
        </div>
        <div className='intro'>
            <div className='text'>
                <p>Welcome to Horizon Optical Drones we will inspire you to think outside the box from a higher view.</p>
                
                <p>At Horizon Optical Drones, we specialize in professional drone photography and videography services, bringing a new perspective to your visual storytelling. 
                With our state-of-the-art drones and experienced pilots, we offer a range of services tailored to meet your needs.</p>
            </div>
            <div className='pics'>
                <img className='home2' src={home2} alt='home2' />
                <img className='home1' src={home1} alt='home1' />
            </div>
        </div>
        <div className='content'>
            <img className='sun' src={sun2} alt='sun' />
            <div className='services'>
                <h1>Services</h1>
                <div className='offers'> 
                    <div>
                        <h2>Aerial Real Estate Photography</h2>
                        <p>Showcase your property listings like never before with captivating aerial images and videos. Our drone photography caplazygftures unique perspectives, highlighting the best features of homes, properties, and real estate listings.</p>
                    </div>
                    <div>
                        <h2>3D Mapping and Imaging</h2>
                        <p>Experience the power of 3D with our advanced drone mapping and imaging services. Our precise aerial data capture techniques enable us to generate detailed 3D models, topographic surveys, and construction site progress updates.</p>
                    </div>
                    <div>
                        <h2>Event Coverage</h2>
                        <p>Elevate your event with captivating aerial coverage. From festivals and sports events to corporate gatherings, our drone services provide dynamic footage and an engaging visual experience for event organizers and attendees.</p>
                    </div>
                    <div>
                        <h2>Wedding Aerial Videography</h2>
                        <p>Make your wedding memories soar with our breathtaking aerial footage. We create cinematic aerial videos that capture the beauty and romance of your special day, adding an unforgettable element to your wedding film.</p>
                    </div>
                    <div>
                        <h2>Environmental and Landscape Photography</h2>
                        <p>Showcasing the beauty of nature is our passion. Our aerial photography brings stunning perspectives to environmental conservation projects, landscapes, and landmarks, inspiring others to appreciate and protect our planet.</p>
                    </div>
                    <div>
                        <h2>Inspections and Surveys</h2>
                        <p>Unlock the efficiency and safety of aerial inspections and surveys. Our drones offer a cost-effective solution for infrastructure inspections, construction site monitoring, and agricultural surveys, providing accurate and timely visual data.</p>
                    </div>
                </div>
            </div>
        </div>
        <hr/>
        <div className='outro'>
            <h1>Discover how our expertise in drone photography and videography can elevate your visual storytelling. Contact today to discuss your project and let us take your vision to new heights.</h1>
            <button><Link to='/contact'>Contact</Link></button>
            <p>Follow us on social media</p>
            <div class="social">
                <a href="https://www.instagram.com/horizon_optical_drones/" target="_blank" rel="noreferrer"><img className='ig' src={ig} alt='instagram' /></a>
                
                <a href="https://www.youtube.com/@horizonopticaldrones" target="_blank" rel="noreferrer"><img className='youtube' src={youtube} alt='youtube' /></a>
            </div>
        </div>
      </div>
    );
  }
  
  export default Home;
  