import React from 'react';
import './eco.scss';
import eco  from '../../assets/contribute.png';
function Eco() {
    return (
        <div className='eco'>
            <img src={eco} title='How we fund climate change.' alt="Eco contribution by Horizon Optical Drones"loading="lazy" />
            <a href="https://climate.stripe.com/5M3BtK" target="_blank" rel="noreferrer" >
            <button>Click to learn more</button>
            </a>
        </div>
    )
}

export default Eco;