import React from 'react';
import './services.scss';
import { Link } from "react-router-dom";
import ig  from '../../assets/square-instagram.svg';
import youtube  from '../../assets/youtube.svg';
function Services() {
    return (
        <div className='services'>
            <div className="title">
                <h1>Services</h1>
            </div>
            <div className="statue">
                <iframe src="https://iframe.mediadelivery.net/embed/144224/191d57a9-073f-4a55-ac8d-dacf868fd960?autoplay=true&loop=true&muted=true&preload=true" title="Soldiers and Sailors Statue"  loading="lazy" allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture;"  allowFullScreen={true}>
                </iframe>
            </div>
            <div className='content'>
                {/* <img className='sun' src={sun2} alt='sun' /> */}
                    <div className='offers'> 
                        <div>
                            <h2>Aerial Real Estate Photography</h2>
                            <p>Showcase your property listings like never before with captivating aerial images and videos. Our drone photography caplazygftures unique perspectives, highlighting the best features of homes, properties, and real estate listings.</p>
                            <div className='preview'>
                                <iframe src="https://iframe.mediadelivery.net/embed/144224/046726ca-13d9-48c0-95e2-470830d78817?autoplay=true&loop=true&muted=true&preload=true" title="Drone Real Estate Video" loading="lazy"  allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture;" allowFullScreen="true">
                                </iframe>
                            </div>
                        </div>
                        <div>
                            <h2>3D Mapping and Imaging</h2>
                            <p>Experience the power of 3D with our advanced drone mapping and imaging services. Our precise aerial data capture techniques enable us to generate detailed 3D models, topographic surveys, and construction site progress updates.</p>
                            <div className="preview model">
                                <iframe src="https://lumalabs.ai/embed/8f4920da-478f-442d-b808-af40fa12f9fc?mode=lf&background=%23ffffff&color=%23000000&showTitle=true&loadBg=true&logoPosition=bottom-left&infoPosition=bottom-right&cinematicVideo=undefined&showMenu=false" frameborder="0" title="luma embed"  loading="lazy"></iframe>
                            </div>
                            <p>This iframe is interactable try playing around with it!</p>
                        </div>
                        <div>
                            <h2>Event Coverage</h2>
                            <p>Elevate your event with captivating aerial coverage. From festivals and sports events to corporate gatherings, our drone services provide dynamic footage and an engaging visual experience for event organizers and attendees.</p>
                            <div className="preview">
                            <iframe src="https://iframe.mediadelivery.net/embed/144224/e2988475-462f-44e8-8528-11c723d41ce6?autoplay=true&loop=true&muted=true&preload=true" title="Drone Shot of West Haven Turkey Run" loading="lazy" allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture;" allowFullScreen="true">
                                </iframe>                            
                            </div>
                        </div>
                        <div>
                            <h2>Wedding Aerial Videography</h2>
                            <p>Make your wedding memories soar with our breathtaking aerial footage. We create cinematic aerial videos that capture the beauty and romance of your special day, adding an unforgettable element to your wedding film.</p>
                            <div className="preview">
                            <iframe src="https://iframe.mediadelivery.net/embed/144224/0c146c97-ebcc-438b-9c71-7f6dcd8d268e?autoplay=true&loop=true&muted=true&preload=true" title="Drone shot of Wedding Couple" loading="lazy" allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture;" allowFullScreen="true"></iframe>                          
                            </div>
                        </div>
                        <div>
                            <h2>Environmental and Landscape Photography</h2>
                            <p>Showcasing the beauty of nature is our passion. Our aerial photography brings stunning perspectives to environmental conservation projects, landscapes, and landmarks, inspiring others to appreciate and protect our planet.</p>
                            <div className="preview">
                            <iframe src="https://iframe.mediadelivery.net/embed/144224/e9eb9fbb-6dc7-449b-a637-3587e3926976?autoplay=true&loop=true&muted=true&preload=true" title="Landscape shot of a forest" loading="lazy" allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture;" allowFullScreen="true"></iframe>                            
                            </div>
                        </div>
                        <div>
                            <h2>Inspections and Surveys</h2>
                            <p>Unlock the efficiency and safety of aerial inspections and surveys. Our drones offer a cost-effective solution for infrastructure inspections, construction site monitoring, and agricultural surveys, providing accurate and timely visual data.</p>
                            <div className="preview">
                            <iframe src="https://iframe.mediadelivery.net/embed/144224/f34aa965-c642-4233-a6d6-947aad402b5b?autoplay=true&loop=true&muted=true&preload=true" title="Drone shot of commercial inspections" loading="lazy" allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture;" allowFullScreen="true"></iframe>
                            </div>
                        </div>
                    </div>
            </div>
            <hr/>
            <div className='outro'>
                <h1>Discover how our expertise in drone photography and videography can elevate your visual storytelling. Contact today to discuss your project and let us take your vision to new heights.</h1>
                <button><Link to='/contact'>Contact</Link></button>
                <p>Follow us on social media</p>
                <div class="social">
                    <a href="https://www.instagram.com/horizon_optical_drones/" target="_blank" rel="noreferrer"><img className='ig' src={ig} alt='instagram' /></a>
                    
                    <a href="https://www.youtube.com/@horizonopticaldrones" target="_blank" rel="noreferrer"><img className='youtube' src={youtube} alt='youtube' /></a>
                </div>
            </div>
        </div>
    )
}

export default Services;